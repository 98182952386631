@font-face {
  font-family: 'SFProText-Regular';
  src: local('SF Pro Text'), url(./SFProText-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText-Bold';
  src: local('SF Pro Text'), url(./SFProText-Bold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText-Medium';
  src: local('SF Pro Text'), url(./SFProText-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText-Semibold';
  src: local('SF Pro Text'), url(./SFProText-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

.react-flow__attribution {
  display: none;
}

.ant-dropdown-menu-item-group-list {
  margin: 0px !important;
}

.ant-dropdown-menu-item {
  padding: 5px 5px !important;
}

.app-list-button .ant-dropdown-menu-item-group-list {
  max-height: 70vh;
  overflow: auto;
}
